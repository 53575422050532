<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<b-row class="mx-4 mt-4">
			<b-col
				:class="{
					'text-center': ['sm', 'xs'].includes(windowWidth),
				}"
				sm="12"
				md="6">
				<h1>
					<strong>
						<span class="text-success">
							Velovita® Vibe
						</span>
						<br v-if="!['sm', 'xs'].includes(windowWidth)">
						App
					</strong>
				</h1>
				<p
					class="font-lg"
					v-html="translate('mobile_app_info_description')" />
				<div>
					<div class="rating">
						<div class="stars">
							<div
								v-for="i in 5"
								:key="i"
								:class="`star-${i}`">
								<svg
									width="20"
									height="20"
									viewBox="0 0 40 40"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										:d="star"
										fill="white" />
								</svg>
							</div>
						</div>
						<p class="pt-3">
							{{ translate('rated') }} 4.7/5.0
						</p>
					</div>
				</div>
				<h2>
					<strong>
						{{ translate('download_app') }}
					</strong>
				</h2>
				<b-row :class="['sm', 'xs'].includes(windowWidth) ? 'mt-3' : ''">
					<div
						:class="['sm', 'xs'].includes(windowWidth) ? 'mt-2' : 'text-left'"
						class="col-md-6 col-sm-12">
						<a
							target="_blank"
							href="https://apps.apple.com/app/velovita-vibe/id1546020462">
							<img
								:src="require(`@/assets/images/common/mobile-app/${language}/app-store.png`)"
								width="100%">
						</a>
					</div>
					<div
						:class="['sm', 'xs'].includes(windowWidth) ? 'mt-2' : 'text-left'"
						class="col-md-6 col-sm-12">
						<a
							target="_blank"
							href="https://play.google.com/store/apps/details?id=com.velovita.app">
							<img
								:src="require(`@/assets/images/common/mobile-app/${language}/play-store.png`)"
								width="100%">
						</a>
					</div>
				</b-row>
			</b-col>
			<b-col
				:class="['sm', 'xs'].includes(windowWidth) ? 'mt-4' : ''"
				sm="12"
				md="6">
				<img
					src="https://velovita.com/wp-content/uploads/2024/05/vibe-app-phones-01.webp"
					width="100%"
					alt="Velovita app">
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Onboarding from '@/util/Onboarding';
import { Onboarding as OnboardingMessage } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'VelovitaVibeStep',
	messages: [OnboardingMessage],
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		stepInfo: {
			type: Object,
			default: () => ({}),
		},
		flowName: {
			type: String,
			default: 'migrated_user_onboarding_distributors_affiliates',
		},
	},
	data() {
		return {
			onboarding: new Onboarding(),
			stepCode: 'mobile_app_info',
		};
	},
	computed: {
		star() {
			return 'M7.65 40L10.9 25.2105L0 15.2632L14.4 13.9474L20 0L25.6 13.9474L40 15.2632L29.1 25.2105L32.35 40L20 32.1579L7.65 40Z';
		},
	},
};
</script>
<style scoped>
.rating {
	align-items: center;
	display: flex;
	gap: 10px;
	//justify-content: center;
}

.stars {
	align-items: center;
	background-color: #df703d;
	border-radius: 10px;
	display: flex;
	gap: 10px;
	justify-content: center;
	padding: 5px 10px;
}
</style>
